import { fetchAuthenticatedContent } from "@parallelpublicworks/entitysync";

export default async function  submitTask(answer, userContext) {
  const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null;
  
  let endpoint = `node/answers`;
  let method = 'POST';
  if(answer.id) {
    endpoint += `/${answer.id}`;
    method = 'PATCH';
  }
  
  let response = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, method, { data: answer });
  
  return response;
}